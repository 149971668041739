<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :pdf-quaility="2"
      :manual-pagination="false"
      pdf-content-width="100%"
      @hasStartedGeneration="hasGenerated($event)"
      :html-to-pdf-options="{
        margin: 0.1,
        filename: user.name + '\'s Account Statement',
        html2canvas: {
            scrollX: 0,
            scrollY: 0,
            scale: 3,
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'landscape'
        },
      }"
      ref="account_statement"
    >
      <section slot="pdf-content">
        <v-card
          class="pa-4 pb-5"
        >
          <v-row>
            <v-col>
              <v-card-title style="padding-bottom: 0; padding-left: 1em;">
                <span style="color: #171B70; font-weight: bold; font-size: 1.5em;" class="">Tran</span>
                <span style="color: #C1A13D; font-weight: bold; font-size: 1.5em;">Save</span>
              </v-card-title>
              <div style="color: #171B70; padding-left: 1.5em; padding-top: 0;">Technologies Limited</div>
              <v-card-text style="color: #171B70; padding-left: 1.5em;">
                35 Ajose Adeogun Street Utako, Abuja
              </v-card-text>
              <v-card-text style="color: #171B70; padding-left: 1.5em; padding-top: 0;">+2348061705599</v-card-text>
            </v-col>

            <v-col align="right" style="color: #171B70; padding-right: 2.5em;">
              <div style="font-size: 1.2em; font-weight: bold; padding-top: 1em;">{{ user.name }}</div>
              <v-card-text style="font-weight: bold; padding: 5px 0 0;">ACCOUNT SUMMARY</v-card-text>
              <div style="padding-right: 0;"><small>ACCT NO: <span>{{ wallet }}</span></small></div>
              <div><small style="padding-right: 0;">Beginning Balance: <span>&#8358;{{ (transactions.length) ? transactions[0].user_balance_after : 0 }}</span></small></div>
              <div><small style="padding-right: 0;">
                {{ total_credits }} Credits: <span>&#8358;{{ total_credit_amount }}</span></small>
              </div>
              <div><small style="padding-right: 0;">
                {{ total_debits }} Debits: <span>&#8358;{{ total_debit_amount }}</span></small>
              </div>
              <div><small style="padding-right: 0;">Ending Balance: <span>&#8358;{{ (transactions.length) ? transactions.at(-1).user_balance_after : 0 }}</span></small></div>
            </v-col>
          </v-row>
          <v-toolbar flat style="display: flex; justify-content: center; color: #171B70; font-weight: bold; margin-top: 2em;">STATEMENT OF ACCOUNT</v-toolbar>
          <v-data-table
            :headers="tableHeader"
            class="myTable"
            style="max-width: 97%; margin: auto;"
            :items="transactions"
            :hide-default-footer="true"
            :items-per-page="itemsPerPage"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              <div>{{ item.created_at | date_time }}</div>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <div>&#8358;{{ item.amount | formatPrice }}</div>
            </template>
            <template v-slot:[`item.user_balance_before`]="{ item }">
              <div>&#8358;{{ item.user_balance_before | formatPrice }}</div>
            </template>
            <template v-slot:[`item.user_balance_after`]="{ item }">
              <div>&#8358;{{ item.user_balance_after | formatPrice }}</div>
            </template>
            <template v-slot:[`item.receiver_account`]="{ item }">
              <div>{{ item.receiver_account }} <small>{{ (item.receiver_name) ? '(' + item.receiver_name + ')' : '' }}</small></div>
            </template>
          </v-data-table>
<!--          <v-card-actions class="primary pa-2" style="position: absolute; bottom: 0; width: 94.5%; margin: auto 20px; border-radius: 3px;">-->
<!--            <div class="row">-->
<!--              <div class="col-4" style="color: #ffffff;"><small>Transave Technologies Limited</small></div>-->
<!--              <div class="col-4" style="text-align: center;">-->
<!--                <span style="color: #ffffff; font-weight: bold;">Tran</span>-->
<!--                <span style="color: #C1A13D; font-weight: bold;">Save</span>-->
<!--              </div>-->
<!--              <div class="col-4 white&#45;&#45;text pull-right" align="right">-->
<!--                <small>info@transave.com.ng</small>-->
<!--              </div>-->
<!--            </div>-->
<!--          </v-card-actions>-->
        </v-card>
      </section>
    </vue-html2pdf>

    <v-dialog
      max-width="450"
      v-model="dialog"
    >
      <v-card
        class="pa-4"
        flat
        style="min-height: 200px;"
      >
        <v-card-subtitle class="primary--text text-center font-weight-bold">
          Select Range
        </v-card-subtitle>
        <v-form lazy-validation ref="form">
          <v-row>
            <v-col>
              <v-card-subtitle class="primary--text py-0 px-0">Start Date</v-card-subtitle>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    append-icon="event_note"
                    outlined
                    dense
                    :value="form.start_date"
                    placeholder="Start Date"
                    readonly
                    v-on="on"
                    color="primary"
                    style="background: #fff; height: 40px"
                    :rules="[(v) => !!v || 'Please select a date']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  light
                  v-model="form.start_date"
                  @change="menu = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <v-card-subtitle class="primary--text py-0 px-0">End Date</v-card-subtitle>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    append-icon="event_note"
                    outlined
                    dense
                    :value="form.end_date"
                    placeholder="End Date"
                    readonly
                    v-on="on"
                    color="primary"
                    style="background: #fff; height: 40px"
                    :rules="[(v) => !!v || 'Please select a date']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  light
                  v-model="form.end_date"
                  @change="menu1 = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="px-0 mt-3">
          <v-row>
            <v-col>
              <v-btn
                block
                elevation="0"
                color="grey lighten-3"
                class="primary--text"
                @click="closeDialog()"
              >Close</v-btn>
            </v-col>
            <v-col>
              <v-btn
                elevation="0"
                block
                color="primary"
                :loading="loading"
                loading-text="Please wait"
                @click="$refs.form.validate() ? filterResult() : null"
              >Export</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
    export default {
      data() {
        return {
          tableHeader: [
            { text: 'Date & Time', value: 'created_at', orderable: false, sortable: false },
            { text: 'Transaction', value: 'activity' },
            { text: 'Type', value: 'type' },
            { text: 'Balance Before', value: 'user_balance_before' },
            { text: 'Amount', value: 'amount' },
            { text: 'Recipient Bank', value: 'bank_name' },
            { text: 'Recipient Account', value: 'receiver_account' },
            { text: 'Balance', value: 'user_balance_after' },
          ],
          transactions: [],
          dialog: false,
          menu: false,
          menu1: false,
          form: {},
          loading: false,
          total_credits: 0,
          total_credit_amount: 0,
          total_debits: 0,
          total_debit_amount: 0,
        }
      },

      methods: {
        ...mapActions('auth', ['filter_transactions']),

        openDialog() {
          this.dialog = true;
        },

        closeDialog() {
          this.dialog = false;
        },

        async filterResult() {
          this.loading = true;
          try {
            let response = await this.filter_transactions(this.form);
            this.loading = false;
            let transactions = response.data.transactions.transactions;
            transactions.forEach(item => {
              if (item.payload !== null) {
                let payload = item.payload;
                let debit = 0;
                let credit = 0;
                item.receiver_account = payload.receiver_account ?? payload.recipient_account;
                item.bank_name = payload.bank_name ?? payload.bank;
                item.receiver_name = payload.receiver_name;

                this.total_credit_amount += item.type === 'Credit' ? Math.round(item.amount) : 0;
                this.total_credits += item.type === 'Credit' ? credit+1 : 0;
                this.total_debit_amount += item.type === 'Debit' ? Math.round(item.amount) : 0;
                this.total_debits += item.type === 'Debit' ? debit+1 : 0;

                this.transactions.push(item);
              }
            });
            // this.total_credits = response.data.transactions.total_credits;
            // this.total_debits = response.data.transactions.total_debits;
            // this.total_credit_amount = response.data.transactions.total_credit_amount;
            // this.total_debit_amount = response.data.transactions.total_debit_amount;
            if (this.transactions.length !== 0) {
              this.$toast.success('Please wait, your download will start in a moment.');
              this.$refs.account_statement.generatePdf();
            } else {
              this.$toast.info('No transactions found for the specified period');
            }
            this.closeDialog();
          } catch (error) {
            this.loading = false;
            if (error.response && error.response.status === 500) {
              this.$toast.error('Something went wrong, please try again later.');
            } else {
              this.$toast.error(error.response.data.message);
            }
          }
        }
      },

      computed: {
        ...mapGetters('auth', ['user']),

        wallet() {
          return this.user.accounts.find(
            x => x.account_name === 'Wallet ID'
          ).account_number
        },

        itemsPerPage() {
          return this.transactions.length
        }
      }
    }
</script>

<style>
  .myTable table thead {
    background: #171B70;
  }
  .myTable table thead tr th {
    color: #ffffff !important;
  }
</style>